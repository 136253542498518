import toast from "react-hot-toast";

export function logoutSession() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.reload();
  toast.error("Session Timeout!");
}
export function getTimeDifference(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffMs = endDate - startDate;

  const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  let result = "";
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0 || days > 0) {
    result += `${hours}h `;
  }
  result += `${minutes}m`;

  return result.trim();
}
export function getDecimalHoursDifference(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffMs = endDate - startDate;

  const hours = diffMs / (1000 * 60 * 60); // Convert milliseconds to hours
  return hours.toFixed(3);
}

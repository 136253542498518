import React, { useState, useEffect } from "react";
import NoteContext from "./NoteContext";
import axiosInstance from "./../axios";
import { logoutSession } from "useable/supportFunctions";
import toast from "react-hot-toast";

const NoteState = (props) => {
  const [myaccoutstate, setMyAccountState] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [Coupon, setCoupon] = useState([]);
  const [isCouponLoaded, setIsCouponLoaded] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoaded, setIsNotificationLoaded] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const DeleteVehicle = async (e) => {
    try {
      await axiosInstance.delete("/consumer_dash/my_vechicle_urd/" + e);
      toast.success("Vehicle deleted Successfully.");
      window.location.reload();
    } catch (err) {
      toast.error("Failed to delete the vehicle.");
    }
  };

  const fetchProfileData = async () => {
    try {
      const profileResponse = await axiosInstance.get(
        "/consumer_dash/edit_consumer_profile"
      );
      const profileData = profileResponse.data.response.user;
      setMyAccountState(profileData);
    } catch (err) {
      if (err.response?.status === 401) {
        logoutSession();
      } else {
        toast.error("Something Went Wrong.");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchProfileData();

        const vehiclesResponse = await axiosInstance.get(
          "/consumer_dash/my_vechicle"
        );
        const vehiclesData = vehiclesResponse.data.response;
        setVehicles(vehiclesData);

        setIsCouponLoaded(false);
        const couponResponse = await axiosInstance.get(
          "/consumer_dash/user_coupan"
        );
        setCoupon([couponResponse.data.response.data]);
      } catch (err) {
        if (err.response?.status === 401) {
          logoutSession();
        } else {
          toast.error("Something Went Wrong.");
        }
      } finally {
        setIsCouponLoaded(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!myaccoutstate.id) {
      return;
    }

    const token =
      localStorage.getItem("user__token") ||
      sessionStorage.getItem("user__token") ||
      "";

    const wsUrl = `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET}/ws/consumer_simple_notification/${myaccoutstate.id}/?token=${token}`;
    const io = new WebSocket(wsUrl);

    const handleOpen = () => {
      if (io.readyState === WebSocket.OPEN) {
        setIsNotificationLoaded(false);
      }
    };

    const handleMessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        setNotifications((prevNotifications) => [
          ...data.added_notifications,
          ...data.notifications,
        ]);
        if (!data.added_notifications) {
          setUnreadCount(data.notifications_unread);
        }
      } catch (error) {
        toast.error("Failed to load notifications.");
      }
    };

    const handleError = () => {
      toast.error("WebSocket error occurred.");
    };

    io.onopen = handleOpen;
    io.onmessage = handleMessage;
    io.onerror = handleError;

    return () => {
      io.close();
    };
  }, [myaccoutstate]);

  useEffect(() => {
    if (notifications.length > 0) {
      setIsNotificationLoaded(true);
    }
  }, [notifications]);

  return (
    <NoteContext.Provider
      value={{
        myaccoutstate,
        notifications: notifications.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        ),
        unreadCount,
        vehicles,
        DeleteVehicle,
        Coupon,
        isNotificationLoaded,
        isCouponLoaded,
        fetchProfileData,
      }}
    >
      {props.children}
    </NoteContext.Provider>
  );
};

export default NoteState;

import React, { lazy, memo, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LoaderLoad from "components/Loader";

const HomePage = lazy(() => import("pages/Auth/HomePage/HomePage"));
const NotificationPage = lazy(() =>
  import("pages/Auth/Notification/NotificationPage")
);
const BookingsPage = lazy(() => import("pages/Auth/Bookings/BookingsPage"));
const BookingsViewPage = lazy(() =>
  import("pages/Auth/BookingsViewPage/BookingsViewPage")
);
const ReschedulePage = lazy(() =>
  import("pages/Auth/ReschedulePage/ReschedulePage")
);
const ParkingReservation = lazy(() =>
  import("pages/Auth/Parking-Reservation/ParkingReservation")
);
const TermsConditions = lazy(() =>
  import("pages/LandingPage/TermsAndConditions")
);
const ProfileIndex = lazy(() => import("pages/Auth/Profile/ProfileIndex"));

const AuthRoute = () => {
  return (
    <Suspense fallback={<LoaderLoad height />}>
      <Switch>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/notifications" component={NotificationPage} />
        <Route exact path="/bookings" component={BookingsPage} />
        <Route
          exact
          path="/bookings/:type/:name/:id"
          component={BookingsViewPage}
        />
        <Route
          exact
          path="/reschedule/:type/:name/:id"
          component={ReschedulePage}
        />
        <Route
          exact
          path="/reservation/:name/:id"
          component={ParkingReservation}
        />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Route path="/profile" component={ProfileIndex} />
        <Route path="*" component={() => <Redirect to="/home" />} />
      </Switch>
    </Suspense>
  );
};

export default memo(AuthRoute);
